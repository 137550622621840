<template>
  <ul :class="loggedInner ? 'menu-logged-inner-pages' : 'menu'">
    <li v-if="!isSubaccount" class="sub-hold open">
      <a :class="{ 'hide-mob': !loggedInner }" href="" onclick="return false;">Domains</a>
      <div class="sub">
        <ul>
          <li><a href="/domain/index">My Domains</a></li>
          <li><a href="/index/register-domain">Register domain</a></li>
          <li><a href="/index/bulk-register-domains">Bulk register domain</a></li>
        </ul>
      </div>
    </li>
    <li v-if="!isSubaccount" class="sub-hold open">
      <a
        :class="{ 'hide-mob': !loggedInner }"
        :href="!loggedInner ? '/domain-transfer/monitor' : ''"
        onclick="return false;"
      >
        Transfers
      </a>
      <div class="sub">
        <ul>
          <li><a href="/domain-transfer">Transfer in</a></li>
          <li><a href="/domain-transfer/monitor">Transfer progress</a></li>
        </ul>
      </div>
    </li>
    <li v-if="!isSubaccount" class="sub-hold open">
      <a :class="{ 'hide-mob': !loggedInner }" href="" onclick="return false;">Emails</a>
      <div class="sub">
        <ul>
          <li><a href="/emails">Email Queue</a></li>
          <li><a href="/emails/service-templates">Notification System</a></li>
          <li><a href="/email-verification">Email Verification</a></li>
        </ul>
      </div>
    </li>
    <li><a href="/tx-log">Transaction Logs</a></li>
    <li>
      <a href="/upcoming-renewals">Upcoming Renewals</a>
    </li>
    <li v-if="!!parseInt(allow_default_dns)">
      <a :class="{ 'hide-mob': !loggedInner }" href="/parking">Parking</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MenuMcp',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    allow_default_dns: {
      type: [Number, Boolean],
      default: false,
    },
    loggedInner: {
      type: [Number, Boolean],
      default: false,
    },
    isSubaccount: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped></style>
