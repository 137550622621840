<template>
  <div>
    <div v-if="!!headerData.isLogged" class="topline">
      <div class="container">
        <div class="l">
          <!--<a href="tel:+5078339556">+507 8339556</a> -->
          <a href="mailto:support@pananames.com">support@pananames.com</a>
        </div>
        <!-- end .l-->
        <div class="r">
          <div v-if="isMcp" class="pop-menu">
            <div class="toggle bg-user">
              {{ headerData.user['firstName'] }} {{ headerData.user['lastName'] }}
            </div>
            <ul>
              <template v-if="!isSubaccount">
                <li><a href="/profile">Profile</a></li>
                <li><a href="/profile/whois-profiles">Whois profiles</a></li>
                <li><a href="/profile/name-servers">DNS profile</a></li>
                <li><a href="/profile/host-records">Host records profile</a></li>
                <li><a href="/profile/security">Security</a></li>
                <li v-if="useSubaccount">
                  <a href="/domain-account/my-subaccounts">Subaccounts</a>
                </li>
                <li><a href="/profile/signatures">Signatures</a></li>
              </template>
              <li><a href="/auth/logout">Log out</a></li>
            </ul>
          </div>

          <div v-else class="pop-menu">
            <div class="toggle bg-user">
              {{ headerData.user['firstName'] }} {{ headerData.user['lastName'] }}
            </div>
            <ul>
              <li><a href="/profile">Profile</a></li>
              <li v-if="useSubaccount">
                <a href="/domain-account/my-subaccounts">Subaccounts</a>
              </li>
              <li><a href="/profile/balance">My balance</a></li>
              <li><a href="/profile/security">Security</a></li>
              <li><a href="/auth/logout">Log out</a></li>
            </ul>
          </div>

          <!-- end .pop-menu-->

          <div v-if="isMcp" class="pop-menu">
            <div class="toggle green">${{ headerData.user['balance'] }} USD</div>
            <ul>
              <li><a :href="baseUrl + '/balance/recharge'">Recharge Balance</a></li>
              <li><a :href="baseUrl + '/index/pricing'">My pricing</a></li>
              <li v-if="!isSubaccount"><a :href="baseUrl + '/orders'">Orders</a></li>
              <li><a :href="baseUrl + '/index/transfer-funds'">Transfer Funds</a></li>
              <li><a :href="baseUrl + '/balance/auto-top-up'">Auto Top-up</a></li>
            </ul>
          </div>

          <div v-else class="pop-menu">
            <div class="toggle green">${{ headerData.user['balance'] }}</div>
            <ul>
              <li><a href="/profile/balance">Balance</a></li>
              <li><a href="/invoice">Invoices</a></li>
              <li><a href="/orders">Orders</a></li>
              <li><a href="/profile/add-balance-funds">Add funds</a></li>
              <li v-if="!isMcp"><a href="/profile/auto-top-up">Auto Top-up</a></li>
            </ul>
          </div>

          <!-- end .pop-menu-->
        </div>
        <!-- end .r-->
        <a
          v-if="!isSubaccount"
          class="cartItems"
          :class="headerData.countItemsInCart <= 0 ? 'cart-link null' : 'cart-link'"
          :style="headerData.countItemsInCart <= 0 ? 'color: #d5d5d5;' : ''"
          href="/cart"
        >
          <span v-if="headerData.countItemsInCart > 0">
            {{ headerData.countItemsInCart }} items in Cart
          </span>
          <span v-else>Cart is empty</span>
        </a>
      </div>
      <!-- end .container-->
    </div>
    <!-- end .topline-->

    <header class="header">
      <div class="container">
        <label class="toggle-menu" for="open-menu"></label>

        <label
          v-if="!headerData.isLogged"
          class="toggle-login"
          for="open-login"
          @click="goTo('/auth/login')"
        ></label>
        <label v-else class="toggle-login" for="open-login"></label>

        <!-- logo -->
        <div class="logo">
          <a :href="baseUrl + '/index'">
            <img
              :src="rootImgSrc('/pananames/img/logo.png')"
              width="165"
              height="25"
              alt="Pananames"
            />
          </a>
        </div>
        <!-- end .logo-->

        <div class="logo for-tab">
          <a :href="baseUrl + '/index'">
            <img
              :src="rootImgSrc('/pananames/img/logo-mob.png')"
              width="50"
              height="50"
              alt="Pananames"
            />
          </a>
        </div>
        <!-- end .logo-->

        <template v-if="!headerData.isLogged">
          <!-- contacts-->
          <div class="contacts">
            <!--<a href="tel:+5078339556">+507.8339556</a> -->
            <a href="mailto:support@pananames.com">support@pananames.com</a>
          </div>
          <!-- end .contacts-->

          <input id="open-menu" type="checkbox" class="outtaHere" />
          <span></span>

          <div class="menu-hold">
            <div class="container">
              <label class="toggle-login" for="open-login" @click="goTo('/auth/login')"></label>
              <label class="close" for="open-menu"></label>
              <div class="logo-in">
                <a :href="baseUrl + '/index'">
                  <img
                    :src="rootImgSrc('/pananames/img/logo-menu.png')"
                    width="127"
                    height="71"
                    alt="Pananames"
                  />
                </a>
              </div>
              <!-- end .logo-in-->

              <ul v-if="!isMcp" class="menu">
                <li :class="activeItem === 'index' ? 'active' : ''">
                  <a :href="baseUrl + '/index'">Home</a>
                </li>
                <li
                  :class="
                    activeItem === 'pricing' || activeItem === 'resellers'
                      ? 'sub-hold open active'
                      : 'sub-hold open'
                  "
                >
                  <a class="hide-mob" :href="baseUrl + '/index/pricing'">Pricing</a>
                  <div class="sub">
                    <ul>
                      <li><a :href="baseUrl + '/index/pricing'">Domain pricing</a></li>
                      <li><a :href="baseUrl + '/index/resellers'">Reseller pricing</a></li>
                    </ul>
                  </div>
                </li>
                <li :class="activeItem === 'hosting' ? 'sub-hold open active' : 'sub-hold open'">
                  <a class="hide-mob" :href="baseUrl + '/index/shared-hosting'">Hosting</a>
                  <div class="sub">
                    <ul>
                      <li><a :href="baseUrl + '/index/shared-hosting'">Shared hosting</a></li>
                      <li><a :href="baseUrl + '/index/vps-hosting'">VPS</a></li>
                    </ul>
                  </div>
                </li>
                <li :class="activeItem === 'aboutus' ? 'active' : ''">
                  <template v-if="activeItem === 'aboutus'">About us</template>
                  <a v-else :href="baseUrl + '/index/team'">About us</a>
                </li>
                <li :class="activeItem === 'contact' ? 'active' : ''">
                  <template v-if="activeItem === 'contact'">Support</template>
                  <a v-else :href="baseUrl + '/index/contact'">Support</a>
                </li>
              </ul>

              <ul v-else class="menu">
                <li :class="{ active: activeItem === 'login' }">
                  <span v-if="activeItem === 'login'">Home</span>
                  <a v-else href="/index">Home</a>
                </li>
                <li :class="{ active: activeItem === 'aboutus' }">
                  <span v-if="activeItem === 'aboutus'">About us</span>
                  <a v-else href="/index/team">About us</a>
                </li>
                <li :class="{ active: activeItem === 'contact' }">
                  <span v-if="!activeItem === 'contact'">Support</span>
                  <a v-else href="/index/contact">Support</a>
                </li>
              </ul>

              <!-- end .menu-->
              <ul class="menu for-btns">
                <li :class="activeItem === 'login' ? 'active' : ''">
                  <a class="btn2 bg-white" :href="baseUrl + '/auth/login'">Login</a>
                </li>
                <li v-if="!isMcp" :class="activeItem === 'register' ? 'active' : ''">
                  <template v-if="activeItem === 'register'">Sign up</template>
                  <a v-else class="btn2 bg-green" :href="baseUrl + '/auth/register'">Sign up</a>
                </li>
              </ul>
              <!-- end .menu-->
              <ul class="menu show-touch">
                <li><a :href="baseUrl + '/index/contact'">Support</a></li>
                <li><a :href="baseUrl + '/index/abuse'">Abuse</a></li>
                <li><a :href="baseUrl + '/index/legal'">LEGAL</a></li>
              </ul>
              <!-- end .menu-->
            </div>
            <!-- end .container-->
          </div>
        </template>

        <template v-else>
          <input id="open-menu" type="checkbox" class="outtaHere" />
          <span></span>
          <div class="menu-hold">
            <div class="container">
              <label class="toggle-login" for="open-login"></label>
              <label class="close" for="open-menu"></label>
              <div class="logo-in">
                <a :href="baseUrl + '/index'">
                  <img
                    :src="rootImgSrc('/pananames/img/logo-menu.png')"
                    width="127"
                    height="71"
                    alt="Pananames"
                  />
                </a>
              </div>
              <!-- end .logo-in-->
              <menu-mcp
                v-if="isMcp"
                :allow_default_dns="!!headerData.user['allow_default_dns']"
                :is-subaccount="isSubaccount"
              ></menu-mcp>

              <menu-cp v-else :base-url="baseUrl"></menu-cp>

              <!-- end .menu-->
            </div>
            <!-- end .container-->
          </div>

          <div v-if="isMcp" class="login-menu">
            <input id="open-login" type="checkbox" class="outtaHere" />
            <span></span>
            <div class="menu-hold">
              <div class="container">
                <a class="toggle-exit" href="/auth/logout"></a>
                <label class="close" for="open-login"></label>
                <div class="logo-in">
                  <img
                    :src="rootImgSrc('/pananames/img/logo-menu.png')"
                    width="127"
                    height="71"
                    alt="Pananames"
                  />
                </div>
                <!-- end .logo-in-->
                <div class="hi">
                  {{ headerData.user['firstName'] }} {{ headerData.user['lastName'] }}
                  <br />
                  <span class="green">
                    <a :href="baseUrl + '/balance/recharge'">${{ headerData.user['balance'] }}</a>
                  </span>
                </div>

                <ul class="menu">
                  <template v-if="!isSubaccount">
                    <li><a href="/profile">Profile</a></li>
                    <li><a href="/profile/whois-profiles">Whois profile</a></li>
                    <li><a href="/profile/name-servers">DNS profile</a></li>
                    <li><a href="/profile/host-records">Host records profile</a></li>
                    <li><a href="/profile/security">Security</a></li>
                    <li v-if="useSubaccount">
                      <a href="/domain-account/my-subaccounts">Subaccounts</a>
                    </li>
                    <li><a href="/profile/signatures">Signatures</a></li>
                  </template>
                  <li><a href="/balance/recharge">Recharge Balance</a></li>
                  <li><a href="/index/pricing">My pricing</a></li>
                  <li><a href="/index/transfer-funds">Transfer Funds</a></li>
                  <li><a href="/auth/logout">Log out</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div v-else class="login-menu">
            <input id="open-login" type="checkbox" class="outtaHere" />
            <span></span>
            <div class="menu-hold">
              <div class="container">
                <a class="toggle-exit" href="/auth/logout"></a>
                <label class="close" for="open-login"></label>
                <div class="logo-in">
                  <a :href="baseUrl + '/index'">
                    <img
                      :src="rootImgSrc('/pananames/img/logo-menu.png')"
                      width="127"
                      height="71"
                      alt="Pananames"
                    />
                  </a>
                </div>
                <!-- end .logo-in-->
                <div class="hi">
                  {{ headerData.user['firstName'] }} {{ headerData.user['lastName'] }}
                  <br />
                  <span class="green">${{ headerData.user['balance'] }}</span>
                </div>
                <!-- end .hi-->

                <ul class="menu">
                  <li>
                    <a
                      class="cartItemsMob"
                      :class="headerData.countItemsInCart <= 0 ? 'cart-link null' : 'cart-link'"
                      :style="headerData.countItemsInCart <= 0 ? 'color: #d5d5d5;' : ''"
                      href="/cart"
                    >
                      <span v-if="headerData.countItemsInCart > 0">
                        {{ headerData.countItemsInCart }} items in Cart
                      </span>

                      <span v-else>Cart is empty</span>
                    </a>
                  </li>
                </ul>
                <ul class="menu">
                  <li><a href="/profile">Profile</a></li>
                  <li v-if="useSubaccount">
                    <a href="/domain-account/my-subaccounts">Subaccounts</a>
                  </li>
                  <li><a href="/profile/balance">My balance</a></li>
                  <li><a href="/profile/security">Security</a></li>
                  <li><a href="/auth/logout">Log out</a></li>
                </ul>
                <!-- end .menu-->
                <ul class="menu">
                  <li><a href="/profile/balance">Balance</a></li>
                  <li><a href="/invoice">Invoices</a></li>
                  <li><a href="/profile/add-balance-funds">Add funds</a></li>
                </ul>
                <!-- end .menu-->
              </div>
            </div>
          </div>

          <menu-mcp
            v-if="isMcp"
            :logged-inner="true"
            :allow_default_dns="!!headerData.user['allow_default_dns']"
            :is-subaccount="isSubaccount"
          ></menu-mcp>

          <menu-cp
            v-else
            :logged-inner="true"
            :base-url="baseUrl"
            :active-item="activeItem"
          ></menu-cp>
        </template>

        <div v-if="headerData.countItemsInCart > 0" class="cart-info-touch">
          <div class="inline">
            <div class="cell">
              <span class="itemsInCart">{{ headerData.countItemsInCart }}</span>
              domains in cart
              <strong id="totalPriceOfCartItems" class="green">${{ totalWithoutIcannFee }}</strong>
              <span class="hide-mob2"><br /></span>
              <a class="go" href="/cart">PROCEED TO CART</a>
            </div>
          </div>
        </div>
        <!-- end .cart-info-touch-->
        <div v-else class="cart-info-touch">
          <div class="inline">
            <div class="cell">
              <span class="pic"></span>
              cart is empty
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { ref } from 'vue'
import MenuCp from './components/header/MenuCp'
import MenuMcp from './components/header/MenuMcp'
import roundToDec from '@/utils/roundToDec'

export default {
  name: 'AppHeader',
  components: { MenuCp, MenuMcp },
  setup() {
    const headerData = window.headerData
    const baseUrl = headerData.baseUrl
    const activeItem = ref(headerData.activeItem)
    const isMcp = !!window.mcpRoot
    const isSubaccount = parseInt(headerData.user['userRole']) === 2 // core/library/Intrust/API/Merchant/Role.php
    const useSubaccount = parseInt(headerData.user['subaccounts']) === 1

    function goTo(link) {
      window.location.href = baseUrl ? baseUrl + link : link
    }

    function rootImgSrc(imgSrc) {
      const root = window.srcHeaderRoot ? window.srcHeaderRoot.src : false
      return root ? root + imgSrc : imgSrc
    }

    return {
      headerData,
      baseUrl,
      activeItem,
      isMcp,
      isSubaccount,
      useSubaccount,
      rootImgSrc,
      goTo,
    }
  },
  computed: {
    totalWithoutIcannFee() {
      return roundToDec(window.headerData.pricesItemsInCart - window.headerData.icannFeeInCart)
    },
  },
  mounted() {
    window.headerData.setCartData = this.setCartData
  },
  methods: {
    setCartData(items, price, icannFee) {
      window.headerData.countItemsInCart = items
      window.headerData.pricesItemsInCart = price
      window.headerData.icannFeeInCart = icannFee
      this.$forceUpdate()
    },
  },
}
</script>

<style>
.toggle-login:before,
.toggle-login:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 17px;
  margin: -8.5px 0;
  background-size: 100% auto;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  opacity: 1;
}
.fixed-header .header > .container > .toggle-login:before {
  opacity: 0;
}
.fixed-header .header > .container > .toggle-login:after {
  opacity: 1;
}
.toggle-login:after {
  opacity: 0;
  background-position: 0 -23px;
}
.topline .cartItems:hover {
  text-decoration: underline;
}
</style>
