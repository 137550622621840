import { createApp } from 'vue'
import App from './AppHeader.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const mainHeader = createApp(App)
mainHeader.use(VueAxios, axios)

// now you can see `vueApp` within the browser console
window.vueAppHeader = mainHeader

mainHeader.mount('#app_header')
