<template>
  <ul :class="loggedInner ? 'menu-logged-inner-pages' : 'menu'">
    <li class="sub-hold open">
      <a :class="{ 'hide-mob': !loggedInner }" href="/domain/my-domains" onclick="return false;">
        Domains
      </a>
      <div class="sub">
        <ul>
          <li><a href="/domain/my-domains">My Domains</a></li>
          <li><a href="/index/register-domain">Register domain</a></li>
          <li><a href="/index/bulk-register-domains">Bulk register domain</a></li>
          <li><a href="/action-progress/bulk">Bulk processes</a></li>
          <li><a href="/task-approvement">Tasks approvement</a></li>
        </ul>
      </div>
    </li>
    <li class="sub-hold open">
      <a :class="{ 'hide-mob': !loggedInner }" href="/profile" onclick="return false;">Profiles</a>
      <div class="sub">
        <ul>
          <li><a href="/profile">Account</a></li>
          <li><a href="/profile/whois-profiles">Whois</a></li>
          <li><a href="/profile/name-servers">DNS</a></li>
          <li><a href="/profile/host-records">Host records</a></li>
        </ul>
      </div>
    </li>
    <li class="sub-hold open">
      <a
        :class="{ 'hide-mob': !loggedInner }"
        href="/domain-transfer/monitor"
        onclick="return false;"
      >
        Transfers
      </a>
      <div class="sub">
        <ul>
          <li><a href="/domain-transfer">Transfer in</a></li>
          <li><a href="/domain-transfer/monitor">Transfer progress</a></li>
          <li><a href="/domain-transfer/push-domains">Between accounts</a></li>
          <li><a href="/domain/transfers-made-offers">Made offers</a></li>
          <li><a href="/domain/transfers-received-offers">Received offers</a></li>
        </ul>
      </div>
    </li>
    <li
      :class="
        activeItem === 'pricing' || activeItem === 'resellers'
          ? 'sub-hold open active'
          : 'sub-hold open'
      "
    >
      <a
        :class="{ 'hide-mob': !loggedInner }"
        :href="baseUrl + '/index/pricing'"
        onclick="return false;"
      >
        Pricing
      </a>
      <div class="sub">
        <ul>
          <li><a :href="baseUrl + '/index/pricing'">Domain pricing</a></li>
          <li><a :href="baseUrl + '/index/resellers'">Reseller pricing</a></li>
        </ul>
      </div>
    </li>
    <li :class="activeItem === 'hosting' ? 'sub-hold open active' : 'sub-hold open'">
      <a
        :class="{ 'hide-mob': !loggedInner }"
        :href="baseUrl + '/index/shared-hosting'"
        onclick="return false;"
      >
        Hosting
      </a>
      <div class="sub">
        <ul>
          <li><a :href="baseUrl + '/index/shared-hosting'">Shared hosting</a></li>
          <li><a :href="baseUrl + '/index/vps-hosting'">VPS</a></li>
        </ul>
      </div>
    </li>
    <li><a :href="baseUrl + '/index/team'">Company</a></li>
    <li><a :href="baseUrl + '/index/contact'">Support</a></li>
  </ul>
</template>

<script>
export default {
  name: 'MenuCp',
  props: {
    baseUrl: {
      type: String,
      default: '',
    },
    loggedInner: {
      type: [Boolean, Number],
      default: false,
    },
    activeItem: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>
